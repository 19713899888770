<template>
    <div>
        <v-container v-if="ready">
            <v-card class="pa-4">
                <h1>{{ $t('listposts.title') }}</h1>


                <v-row class="mb-4">
                    <v-col cols=12 class="text-right">
                        <v-btn icon @click="reload_posts()">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                        <v-btn class="ml-2" :color="blueColor" :to="{ name: 'NewPost' }">
                            <span class="white--text">
                                {{ $t('listposts.newpost') }}
                            </span>
                        </v-btn>



                    </v-col>
                </v-row>
                <!-- hide-default-header -->
                <v-data-table hide-default-footer item-key="nome" :headers="headers" :items="posts" :items-per-page="50"
                    class="elevation-1">


                    <template v-slot:item.creation="{ item }">
                        {{ to_human_data(item.creation) }}
                    </template>

                    <template v-slot:item.title="{ item }">
                        <!-- <div class="my-3" v-for="(title, language,index) in item.title" :key="index"> -->
                        <!-- <flag :iso="languageConverted(language)" v-bind:squared="false" /> -->

                        <!-- <span>
                            [{{language}}]
                        </span> -->

                        <strong>
                            <!-- {{ title }} -->
                            {{ title_selectedLanguage(item) }}
                        </strong>
                        <!-- </div> -->
                    </template>

                    <template v-slot:item.available_languages="{ item }">
                        <span>
                            <!-- {{ language_available(item) }} -->
                            <flag class="mx-1" :iso="languageConverted(language)" v-bind:squared="false"
                                v-for="language in language_available(item)" :key="language" />
                        </span>

                    </template>


                    <template v-slot:item.images="{ item }">
                        <span v-if="item.images">
                            {{ item.images.length }}
                            <v-icon>
                                mdi-panorama-variant-outline
                            </v-icon>
                        </span>
                        <span v-else>
                            ---
                        </span>
                    </template>

                    <template v-slot:item.stato="{ item }">
                        <v-chip small v-if="item.stato == 0" color="green">
                            <strong class="white--text">
                                {{ human_status(item.stato) }}
                            </strong>
                        </v-chip>

                        <v-chip small v-if="item.stato == 1" color="orange">
                            <strong class="black--text">
                                {{ human_status(item.stato) }}
                            </strong>
                        </v-chip>

                        <v-chip small v-if="item.stato == 2" color="blue">
                            <strong class="white--text">
                                {{ human_status(item.stato) }}
                            </strong>
                        </v-chip>

                    </template>


                    <template v-slot:item.actions="{ item }">

                        <!-- {{ item.id }} -->
                        <v-btn icon small :to="{ name: 'EditPost', params: { id_articolo: item.id } }">
                            <v-icon small>mdi-pen</v-icon>
                        </v-btn>

                    </template>
                </v-data-table>

                <v-row class="mt-4">
                    <v-col cols=12 class="text-center">
                        <a v-if="more_available_articoli" class="secondary--text" @click="load_more()">
                            <strong :style="negative_color_style">
                                {{ $t('listposts.load_oldestposts') }}
                            </strong>
                        </a>
                    </v-col>
                </v-row>

                <BackToAdminPanel />

            </v-card>
        </v-container>
    </div>
</template>

<script>

import i18n from '@/plugins/i18n';
import BackToAdminPanel from '@/components/BackToAdminPanel.vue'


export default {
    props: {},
    components: {
        BackToAdminPanel,
    },
    data: () => ({
        ready: false
    }),
    methods: {
        get_posts() {
            this.$store.dispatch("articoli/act_get_article_batch_from_latest_include_draft", 5).then(() => {
                this.ready = true
            })
        },
        reload_posts() {
            this.$store.dispatch("articoli/act_clear_articoli").then(() => {
                this.$store.dispatch("articoli/act_get_article_batch_from_latest_include_draft", 5).then(() => {
                    this.ready = true
                })
            })
        },
        load_more() {
            this.$store.dispatch("articoli/act_get_article_batch_from_latest_include_draft")
        },

        to_human_data(data) {
            try {
                if (data) {
                    // console.log(data);
                    return new Date(data * 1000).toLocaleDateString('it-it', { year: 'numeric', month: '2-digit', day: '2-digit' })
                    // return data.toDate().toLocaleDateString('it-it', { year: 'numeric', month: '2-digit', day: '2-digit' })
                } else {
                    return null
                }
            } catch {

            }
        },
        languageConverted(language) {
            if (language == 'en') return 'gb'
            return language
        },
        language_available(post) {
            let available = []
            Object.keys(post.title).forEach(key => {
                // console.log(key, post.title[key]);
                if (post.body[key] != '' && post.body[key] != undefined && post.body[key] != null) {
                    available.push(key)
                }
            })
            return available.sort().reverse()
        },
        title_selectedLanguage(post) {
            if (post.title[i18n.locale]) {
                return post.title[i18n.locale]
            } else {
                return post.title['it']
            }
        },
        human_status(status) {
            switch (status) {
                case 0:
                    return this.$t('listposts.status.published')
                case 1:
                    return this.$t('listposts.status.draft')
                case 2:
                    return this.$t('listposts.status.archived')
                // case 2:
                //     return this.$t('listposts.ststus.published')

                default:
                    break;
            }
        }
    },
    mounted() {
        this.get_posts()
    },
    created() { },
    computed: {
        more_available_articoli() {
            return this.$store.getters["articoli/get_more_available_articoli"]
        },
        posts() {
            return this.$store.getters["articoli/get_articoli"]
        },
        headers() {
            return [

                {
                    text: this.$t('listposts.tableheader.title'),
                    align: 'start',
                    sortable: false,
                    value: 'title',
                },

                {
                    text: this.$t('listposts.tableheader.available_languages'),
                    align: 'start',
                    sortable: false,
                    value: 'available_languages',
                },

                {
                    text: this.$t('listposts.tableheader.data'),
                    align: 'start',
                    sortable: true,
                    value: 'creation',
                },
                {
                    text: this.$t('listposts.tableheader.imgs_count'),
                    align: 'center',
                    sortable: false,
                    value: 'images',
                },

                {
                    text: this.$t('listposts.tableheader.status'),
                    align: 'center',
                    sortable: false,
                    value: 'stato',
                },
                { text: '', value: 'actions', align: 'end', },
            ]
        },

    },
    watch: {}
}
</script>

<style>

</style>